import Vue from 'vue';
import Router, {RouteConfig} from 'vue-router';
import {auth} from '@/plugins/firebase.init';
import {appDomain, dynamicLinkConfig} from '@/plugins/firebase.config';

Vue.use(Router);

const homeRoute = {
  path: '/home',
  name: 'home',
  component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
  meta: {
    requiresAuth: true,
    title: 'Home'
  },
  children: [
    {
      path: 'get-started',
      name: 'get-started',
      component: () => import(/* webpackChunkName: "get-started" */ './components/home/GetStarted.vue'),
      meta: {
        requiresAuth: true,
        title: 'Get Started'
      }
    },
    {
      path: 'discover',
      name: 'discover',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      meta: {
        requiresAuth: true,
        title: 'Discover'
      }
    },
    {
      path: 'features',
      name: 'features',
      component: () => import(/* webpackChunkName: "features" */ './components/home/Features.vue'),
      meta: {
        requiresAuth: true,
        title: 'Features'
      }
    }
  ]
};
const requestsLegacyRoute: RouteConfig[] = [
  {
    path: '/requests/:type/:subtype/:chatId',
    redirect: (to) => ({
      path: '/requests/:type/:chatId',
      query: {type: to.params.type, subtype: to.params.subtype}
    })
  }
]
const requestsRoute: RouteConfig = {
  path: '/requests',
  name: 'requests',
  component: () => import(/* webpackChunkName: "requests" */ './components/requests/Requests.vue'),
  meta: {
    requiresAuth: true,
    title: 'Requests'
  },
  children: [
    {
      path: 'appointments/:appointId',
      name: 'appointments',
      component: () => import(/* webpackChunkName: "appoints" */ './components/Appointments.vue'),
      props: (route) => ({
        appointId: route.params.appointId,
        subtype: route.query.subtype
      }),
      meta: {
        requiresAuth: false,
        title: 'Appointments'
      }
    },
    {
      path: 'inbox',
      name: 'inbox',
      component: () => import(/* webpackChunkName: "inbox" */ './components/requests/Inbox.vue'),
      props: (route) => ({
        chatId: route.params.chatId,
        type: route.query.type,
        subtype: route.query.subtype
      }),
      meta: {
        requiresAuth: false,
        title: 'Inbox'
      },
      children: [
        {
          path: ':chatId?',
          name: 'inbox-request',
          props: (route) => ({
            chatId: route.params.chatId,
            type: route.query.type,
            subtype: route.query.subtype
          }),
          component: () => import(/* webpackChunkName: "chat-screen" */ './components/ChatScreen2.vue'),
          meta: {
            title: 'Inbox Request'
          }
        }
      ]
    },
    {
      path: 'rejected',
      name: 'rejected',
      component: () => import(/* webpackChunkName: "rejected" */ './components/requests/Inbox.vue'),
      props: (route) => ({
        chatId: route.params.chatId,
        type: route.query.type,
        subtype: route.query.subtype
      }),
      meta: {
        requiresAuth: false,
        title: 'Rejected'
      },
      children: [
        {
          path: ':chatId?',
          name: 'rejected-request',
          props: (route) => ({
            chatId: route.params.chatId,
            type: route.query.type,
            subtype: route.query.subtype
          }),
          component: () => import(/* webpackChunkName: "chat-screen" */ './components/ChatScreen2.vue'),
          meta: {
            title: 'Rejected Request'
          }
        }
      ]
    },
  ]
};
const chatsLegacyRoute: RouteConfig[] = [
  {
    path: '/chats/:type/:subtype/:chatId',
    redirect: (to) => ({
      path: '/chats/:type/:chatId',
      query: {type: to.params.type, subtype: to.params.subtype}
    })
  }
]
const chatsRoute: RouteConfig = {
  path: '/chats',
  name: 'chats',
  component: () => import(/* webpackChunkName: "chats" */ './components/Chats.vue'),
  props: true,
  meta: {
    requiresAuth: true,
    title: 'Chats'
  },
  children: [
    {
      path: 'active',
      name: 'active',
      component: () => import(/* webpackChunkName: "active-chats" */ './components/ActiveChats.vue'),
      props: (route) => ({
        chatId: route.params.chatId,
        type: route.query.type,
        subtype: route.query.subtype,
        newChat: route.query.newChat
      }),
      meta: {
        requiresAuth: false,
        title: 'Active'
      },
      children: [
        {
          path: 'not-found',
          name: 'active-not-found',
          props: false,
          component: () => import(/* webpackChunkName: "not-found" */ './views/PageNotFound.vue'),
          meta: {
            requiresAuth: false,
            title: 'Active Chat Not Found'
          }
        },
        {
          path: ':chatId?',
          name: 'active-chat',
          props: (route) => ({
            chatId: route.params.chatId,
            type: route.query.type,
            subtype: route.query.subtype,
            newChat: !!route.query.newChat
          }),
          component: () => import(/* webpackChunkName: "chat-screen" */ './components/ChatScreen2.vue'),
          meta: {
            requiresAuth: false,
            title: 'Active Chat'
          }
        }
      ]
    },
    {
      path: 'inner',
      name: 'inner',
      component: () => import(/* webpackChunkName: "inner-chats" */ './components/InnerChats.vue'),
      props: (route) => ({
        chatId: route.params.chatId,
        type: route.query.type,
        subtype: route.query.subtype,
        newChat: !!route.query.newChat
      }),
      meta: {
        requiresAuth: false,
        title: 'Inner'
      },
      children: [
        {
          path: 'not-found',
          name: 'inner-not-found',
          props: false,
          component: () => import(/* webpackChunkName: "not-found" */ './views/PageNotFound.vue'),
          meta: {
            requiresAuth: false,
            title: 'Inner Chat Not Found'
          }
        },
        {
          path: ':chatId?',
          name: 'inner-chat',
          props: (route) => ({
            chatId: route.params.chatId,
            type: route.query.type,
            subtype: route.query.subtype,
            newChat: !!route.query.newChat
          }),
          component: () => import(/* webpackChunkName: "chat-screen" */ './components/ChatScreen2.vue'),
          meta: {
            requiresAuth: false,
            title: 'Inner Chat'
          }
        }
      ]
    },
    {
      path: 'channels',
      name: 'channels',
      component: () => import(/* webpackChunkName: "active-chats" */ './components/Channels.vue'),
      props: (route) => ({}),
      meta: {
        requiresAuth: false,
        title: 'Channels'
      },
      children: [
        {
          path: 'not-found',
          name: 'channel-not-found',
          props: false,
          component: () => import(/* webpackChunkName: "not-found" */ './views/PageNotFound.vue'),
          meta: {
            requiresAuth: false,
            title: 'Channel Not Found'
          }
        },
        {
          path: ':channelId?',
          name: 'channel-detail',
          props: (route) => ({
            channelId: route.params.channelId,
          }),
          component: () => import(/* webpackChunkName: "chat-screen" */ './components/ChannelDetail.vue'),
          meta: {
            requiresAuth: false,
            title: 'Active Chat'
          }
        }
      ]
    },
    {
      path: 'archive/:archiveId?',
      name: 'archive',
      props: (route) => ({
        archiveId: route.params.archiveId,
        type: route.query.type,
        subtype: route.query.subtype
      }),
      component: () => import(/* webpackChunkName: "archive" */ './components/Archive.vue'),
      meta: {
        requiresAuth: false,
        title: 'Archive'
      }
    }
  ]
};
const contactsRoute: RouteConfig = {
  path: '/contacts',
  name: 'contacts',
  component: () => import(/* webpackChunkName: "contacts" */ './components/Contacts2.vue'),
  props: true,
  meta: {
    requiresAuth: true,
    title: 'Contacts'
  },
  children: [
    {
      path: 'customers/:type',
      name: 'customers',
      component: () => import(/* webpackChunkName: "customers" */ './components/business/customers/Customers.vue'),
      props: true,
      meta: {
        title: 'Customers'
      },
      children: [
        {
          path: ':customerId',
          name: 'customer-profile',
          component: () => import(/* webpackChunkName: "customer-profile" */ './components/business/customers/CustomerProfile.vue'),
          props: true,
          meta: {
            title: 'Customer Profile'
          }
        }
      ]
    },
    {
      path: 'directory',
      component: () => import(/* webpackChunkName: "directory" */ './components/Directory.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'Directory'
      },
      children: [
        {
          path: '',
          name: 'directory',
          component: () => import(/* webpackChunkName: "directory-tree" */ './components/DirectoryTree.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Directory'
          }
        },
        {
          path: ':contactId',
          name: 'contact-info',
          component: () => import(/* webpackChunkName: "contact-info" */ './components/ContactInfo2.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            title: 'Contact Info'
          }
        }
      ]
    },
    {
      path: 'personal',
      name: 'personal',
      component: () => import(/* webpackChunkName: "personal-contacts" */ './components/PersonalContacts.vue'),
      meta: {
        requiresAuth: true,
        title: 'Personal Contacts'
      },
      children: [
        {
          path: 'customers/:customerId',
          name: 'personal-customer-profile',
          component: () => import(/* webpackChunkName: "customer-profile" */ './components/business/customers/CustomerProfile.vue'),
          props: true,
          meta: {
            title: 'Customer Profile'
          }
        }
      ]
    },
    {
      path: 'shared',
      name: 'shared',
      component: () => import(/* webpackChunkName: "shared-contacts" */ './components/SharedContacts.vue'),
      meta: {
        requiresAuth: true,
        title: 'Shared Contacts'
      },
      children: [
        {
          path: 'customers/:customerId',
          name: 'shared-customer-profile',
          component: () => import(/* webpackChunkName: "customer-profile" */ './components/business/customers/CustomerProfile.vue'),
          props: true,
          meta: {
            title: 'Customer Profile'
          }
        }
      ]
    },
    {
      path: 'partnerships',
      name: 'contacts-partnerships',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      meta: {
        requiresAuth: true,
        title: 'Partnerships'
      }
    }
  ]
};
const reportsRoute: RouteConfig = {
  path: '/reports',
  name: 'reports',
  component: () => import(/* webpackChunkName: "reports" */ './views/Reports.vue'),
  meta: {
    requiresAuth: true,
    title: 'Reports'
  },
  children: [
    {
      path: 'dashboard',
      name: 'dashboard',
      component: () => import(/* webpackChunkName: "dashboard" */ './components/Dashboard.vue'),
      meta: {
        requiresAuth: true,
        title: 'Dashboard'
      }
    },
    {
      path: 'benchmarks',
      name: 'benchmarks',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      meta: {
        requiresAuth: true,
        title: 'Benchmarks'
      }
    }
  ]
};
const businessRoute: RouteConfig = {
  path: '/business',
  name: 'business',
  component: () => import(/* webpackChunkName: "business" */ './components/Business.vue'),
  props: true,
  meta: {
    requiresAuth: true,
    title: 'Business'
  },
  children: [
    {
      path: 'company',
      name: 'company-profile',
      component: () => import(/* webpackChunkName: "company-profile" */ './components/business/CompanyProfile.vue'),
      meta: {
        requiresAuth: true,
        title: 'Company Profile'
      }
    },
    {
      path: 'management',
      name: 'management',
      component: () => import(/* webpackChunkName: "user-management" */ './components/UserManagement.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        title: 'User Management'
      },
      children: [
        {
          path: 'users',
          name: 'users',
          component: () => import(/* webpackChunkName: "users" */ './components/Users.vue'),
          props: (route) => ({
            tab: parseInt(route.query.tab as string, 10)
          }),
          meta: {
            requiresAuth: true,
            title: 'Users'
          }
        },
        {
          path: ':userId',
          name: 'user-info',
          component: () => import(/* webpackChunkName: "user-info" */ './views/business/users/UserInfo.vue'),
          props: true,
          meta: {
            requiresAuth: true,
            title: 'User Info'
          }
        }
      ]
    },
    {
      path: 'organization',
      name: 'organization',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      meta: {
        requiresAuth: true,
        title: 'Organization'
      }
    },
    {
      path: 'partnerships',
      name: 'partnerships',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      meta: {
        requiresAuth: true,
        title: 'Partnerships'
      }
    },
    {
      path: 'customers',
      name: 'customer-management',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      // component: () => import(/* webpackChunkName: "customer-management" */ './components/CustomerManagement.vue'),
      meta: {
        requiresAuth: true,
        title: 'Customer Management'
      }
    }
  ]
};
const settingsRoute = {
  path: '/settings',
  name: 'settings',
  component: () => import(/* webpackChunkName: "settings" */ './views/Settings.vue'),
  meta: {
    requiresAuth: true,
    title: 'Settings'
  },
  children: [
    {
      path: 'localisation',
      name: 'settings-localisation',
      component: () => import(/* webpackChunkName: "settings-localisation" */ './components/settings/Localisation.vue'),
      meta: {
        requiresAuth: true,
        title: 'Localisation'
      }
    },
    {
      path: 'schedules',
      name: 'settings-schedules',
      component: () => import(/* webpackChunkName: "settings-schedules" */ './components/settings/Schedules.vue'),
      meta: {
        requiresAuth: true,
        title: 'Schedules'
      }
    },
    {
      path: 'notifications',
      name: 'settings-notifications',
      component: () => import(/* webpackChunkName: "settings-notifications" */ './components/settings/Notifications.vue'),
      meta: {
        requiresAuth: true,
        title: 'Notifications'
      }
    },
    {
      path: 'away-messages',
      name: 'settings-away-messages',
      component: () => import(/* webpackChunkName: "settings-away-messages" */ './components/settings/AwayMessages.vue'),
      meta: {
        requiresAuth: true,
        title: 'Automatic messages'
      }
    },
    {
      path: 'backup',
      name: 'settings-backup',
      component: () => import(/* webpackChunkName: "settings-backup" */ './components/settings/Backup.vue'),
      meta: {
        requiresAuth: true,
        title: 'Backup'
      }
    },
    {
      path: 'appointments',
      name: 'settings-appointments',
      component: () => import(/* webpackChunkName: "settings-appoints" */ './components/settings/Appointments.vue'),
      meta: {
        requiresAuth: true,
        title: 'Appointments'
      }
    },
    {
      path: 'contacts',
      name: 'settings-contacts',
      component: () => import(/* webpackChunkName: "settings-contacts" */ './components/settings/Contacts.vue'),
      meta: {
        requiresAuth: true,
        title: 'Contacts'
      }
    },
    {
      path: 'business-page-access',
      name: 'settings-page-access',
      component: () => import(/* webpackChunkName: "settings-page-access" */ './components/settings/BusinessPageAccess.vue'),
      meta: {
        requiresAuth: true,
        title: 'Business Page Access'
      }
    },
    {
      path: 'business-live-chat',
      name: 'settings-live-chat',      
      component: () => import(/* webpackChunkName: "settings-live-chat" */ './components/settings/PigeonLiveChat.vue'),
      meta: {
        requiresAuth: true,
        title: 'Pigeon Live Chat'
      }
    },
    {
      path: 'archiving',
      name: 'settings-archiving',
      component: () => import(/* webpackChunkName: "settings-archiving" */ './components/settings/Archiving.vue'),
      meta: {
        requiresAuth: true,
        title: 'Archiving'
      }
    },
  ]
};
const enterpriseRoute = {
  path: 'enterprise',
  name: 'enterprise',
  component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
  meta: {
    requiresAuth: true,
    title: 'Enterprise'
  }
};
const notificationRoutes = {
  path: 'notifications',
  name: 'notifications',
  component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
  meta: {
    requiresAuth: true,
    title: 'Notifications'
  }
};
const accessDeniedRoutes = {
  path: '/access-denied',
  name: 'access-denied',
  component: () => import(/* webpackChunkName: "access-denied" */ './views/PageAccessDenied.vue'),
  meta: {
    requiresAuth: true,
    title: 'Access denied'
  }
};

const faqRoutes = {
  path: 'faq',
  name: 'faq',
  component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
  meta: {
    requiresAuth: true,
    title: 'FAQ'
  }
};
const profileRoutes = {
  path: '/profile',
  name: 'profile',
  component: () => import(/* webpackChunkName: "profile" */ './views/profile/Profile.vue'),
  meta: {
    requiresAuth: true,
    title: 'Profile'
  },
  children: [
    {
      path: 'overview',
      name: 'profile-overview',
      component: () => import(/* webpackChunkName: "profile-overview" */ './components/profile/ProfileOverview.vue'),
      meta: {
        requiresAuth: true,
        title: 'Profile Overview'
      }
    },
    {
      path: 'archive/:archiveId?',
      name: 'profile-archive',
      props: (route) => ({
        archiveId: route.params.archiveId,
        type: route.query.type,
        subtype: route.query.subtype
      }),
      component: () => import(/* webpackChunkName: "archive" */ './components/Archive.vue'),
      meta: {
        requiresAuth: true,
        title: 'Archive'
      }
    },
    {
      path: 'security',
      name: 'profile-security',
      component: () => import(/* webpackChunkName: "privacy-security" */ './views/profile/privacy-security/PrivacyAndSecurity.vue'),
      meta: {
        requiresAuth: true,
        title: 'Security & Privacy'
      }
    },
    {
      path: 'block-list',
      name: 'block-list',
      component: () => import(/* webpackChunkName: "block-list" */ './views/profile/block-list/BlockedUsers.vue'),
      meta: {
        requiresAuth: true,
        title: 'Blocked Users'
      },
      children: [
        {
          path: ':customerId',
          name: 'blocked-customer-profile',
          component: () => import(/* webpackChunkName: "customer-profile" */ './components/business/customers/CustomerProfile.vue'),
          props: true,
          meta: {
            title: 'Customer Profile'
          }
        }
      ]
    },
    {
      path: 'help',
      name: 'profile-help',
      component: () => import(/* webpackChunkName: "empty-view" */ './components/EmptyView.vue'),
      meta: {
        requiresAuth: true,
        title: 'Help'
      }
    }
  ]
};
const mainRoute: RouteConfig = {
  path: '/mainpage',
  name: 'MainPage',
  component: () => import(/* webpackChunkName: "main" */ './views/MainPage.vue'),
  meta: {
    requiresAuth: true,
    title: 'Pigeon Business'
  },
  children: [
    homeRoute,
    ...requestsLegacyRoute, // todo: to eliminate
    requestsRoute,
    ...chatsLegacyRoute, // todo: to eliminate
    chatsRoute,
    contactsRoute,
    reportsRoute,
    businessRoute,
    settingsRoute,
    enterpriseRoute,
    notificationRoutes,
    faqRoutes,
    profileRoutes,
    accessDeniedRoutes
  ]
}

const authRoute: RouteConfig = {
  path: '/auth/:step',
  name: 'auth',
  props: true,
  component: () => import(/* webpackChunkName: "auth" */ './components/Auth.vue'),
  meta: {
    title: 'Authentication'
  }
}

const authRedirectRoute: RouteConfig = {
  path: '/auth',
  redirect: '/auth/sign-up'
}

// customer must be in shared section to be displayed
const customerDLResolver: RouteConfig = {
  path: '/businesses/:businessId/contacts/customers/:customerId',
  redirect: (to) => ({
    name: 'shared-customer-profile',
    params: {
      businessId: to.params.businessId,
      customerId: to.params.customerId
    }
  })
}

const businessContactDLResolver: RouteConfig = {
  path: '/businesses/:businessId/contacts/directory/:contactId',
  redirect: (to) => ({
    name: 'contact-info',
    params: {
      businessId: to.params.businessId,
      contactId: to.params.contactId
    }
  })
}

const businessUserProfileDLResolver: RouteConfig = {
  path: '/businesses/:businessId/users/:userId',
  redirect: (to) => ({
    name: 'user-info',
    params: {
      businessId: to.params.businessId,
      userId: to.params.userId
    }
  })
}

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      redirect: {name: 'get-started'}
    },
    {
      path: '',
      redirect: {name: 'get-started'}
    },
    mainRoute,
    authRoute,
    authRedirectRoute,
    customerDLResolver,
    businessContactDLResolver,
    businessUserProfileDLResolver,
    {
      path: '*',
      component: () => import(/* webpackChunkName: "not-found" */ './views/PageNotFound.vue')
    }
  ]
});

router.beforeEach((to, from, next) => {
  const domain: string = window.location.hostname;
  if (domain === dynamicLinkConfig.domain) {
    window.location.replace(`https://${appDomain}`);
    return;
  }
  document.title = to.meta.title;
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!auth.currentUser) {
      next({
        name: 'auth',
        params: {step: 'sign-in'},
        query: {redirect: to.fullPath},
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
